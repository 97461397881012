import { notify } from 'notiwind'

function n({ timeout = 4000, ...notification }: Notification) {
  notify(notification, timeout)
}

export interface Notification {
  /**
   * @default 4000
   */
  timeout?: number

  /**
   * @default `alerts`
   */
  group?: string

  /**
   * @default `success`
   */
  type?: string

  title?: string
  text?: string
}

export function useNotify() {
  return {
    success: (notification: Notification) => n({ type: 'success', group: 'alerts', ...notification }),
    error: (notification: Notification) => n({ type: 'error', group: 'alerts', ...notification }),
  }
}
